import React from 'react';
import { Link, navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContainerLeft from '../components/common/container-left';
import MainContent from '../components/common/main-content';
import ContainerRight from '../components/common/container-right';
import Button from '../components/common/button';
import logoSrc from '../assets/logo2x.png';
import likeSrc from '../assets/icon-like.svg';

const fadeInSlide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Content = styled.div`
  @media(min-width: 960px) {
    display: flex;
    min-height: 100vh;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  max-width: 201px;

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
`;

const Thanks = styled.div`
  animation: ${fadeInSlide} 500ms ease-in-out 500ms forwards;
  opacity: 0;
  padding: 32px;
  position: relative;
  transform: translateY(-16px);

  @media(min-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 10%;
  }
`;

const Icon = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 64px;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: normal;
  margin: 16px 0;
`;

const Text = styled.p`
  margin: 0 0 45px 0;
`;

const StyledButton = styled(Button)`
  font-weight: normal;
`;

const ThanksPage = () => (
  <Layout>
    <SEO title="Thanks" keywords={['gmdn', 'gmdn enroll']} />
    <Content>
      <ContainerLeft>
        <StyledLink to="/">
          <img src={logoSrc} alt="GMDN Enroll" />
        </StyledLink>
        <MainContent />
      </ContainerLeft>
      <ContainerRight fullWidth>
        <Thanks>
          <Icon>
            <img src={likeSrc} alt="Thumbs up" />
          </Icon>
          <Title>Success!</Title>
          <Text>
            Thanks for reaching out to us! We will be in contact with you as soon as possible.
          </Text>
          <div>
            <StyledButton secondary onClick={() => navigate('/')}>
              Sounds Good
            </StyledButton>
          </div>
        </Thanks>
      </ContainerRight>
    </Content>
  </Layout>
);

export default ThanksPage;
